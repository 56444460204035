import CardBox from "@/components/Common/CardBox";
import FlexiDataTable from "@/components/FlexiDataTable";
import { CALLBACK_KEY, ComponentType } from "@/constants";
import { REQUIRED_FIELD } from "@/constants/errorMessage";
import { FlexiDataTableCallbackProps, FlexiDataTableOptionsProps, KeyValuePair } from "@/constants/type";
import { APIs } from "@/services/apis";
import { plainAxiosInstance } from "@/services/axiosSetup";
import { SortList } from "@/utils/array";
import { DTColProps } from "@/utils/Common";
import { DefaultIfEmpty } from "@/utils/object";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";

interface TransactionOrderCheckerProps {}

interface TransactionOrderCheckerDataProps {
    server: string;
    login: string;
    categories: { [key: string]: TransactionOrderCheckerCategoriesItemProps };
}

interface TransactionOrderCheckerCategoriesItemProps {
    subType: { [key: number]: boolean };
    status: boolean;
}

const SubType: { [key: string]: string } = {
    "0": "PE",
    "1": "OZ Normal",
    "2": "OZ Stock",
    "3": "Others",
};

const TransactionOrderChecker = (props: TransactionOrderCheckerProps) => {
    const [runRefetchDataList, setRunRefetchDataList] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [data, setData] = useState<TransactionOrderCheckerDataProps[]>([]);
    const [filterParams, setFilterParams] = useState<any>({});
    const [servers, setServers] = useState<KeyValuePair[]>([]);

    const markupData = useMemo(() => {
        return SortList(
            data.map((x: any, xIdx: number) => ({
                key: `${x.server}-${xIdx}`,
                server: x.server,
                login: x.login,
                ...Object.keys(x.categories).reduce((yItem: any, ykey: string) => {
                    yItem[`${ykey}_ORI`] = x.categories[ykey].status;
                    return {
                        ...yItem,
                        ...Object.keys(x.categories[ykey].subType).reduce((zItem: any, zKey: string) => {
                            zItem[`${ykey}_${zKey}`] = x.categories[ykey].subType[zKey];
                            return zItem;
                        }, {}),
                    };
                }, {}),
            })),
            "server"
        );
    }, [data]);

    const columns: any[] = useMemo(() => {
        let basicColumns = [
            {
                title: "Search Date Time",
                dataIndex: "dateTime",
                key: "dateTime",
                options: {
                    visible: false,
                    filter: {
                        type: ComponentType.date,
                        value: "",
                        dateFormat: "YYYY-MM-DD HH:mm",
                        rules: [{ required: true, message: REQUIRED_FIELD }],
                        inputProps: {
                            showTime: { format: "HH:mm" },
                            disabledDate: (current: any) => {
                                return current && current > moment().endOf("day");
                            },
                        },
                    },
                },
            },
            DTColProps.Small({
                title: "Server",
                dataIndex: "server",
                key: "server",
                options: {
                    filter: {
                        type: ComponentType.dropdown,
                        value: servers,
                        rules: [{ required: true, message: REQUIRED_FIELD }],
                        inputProps: {
                            mode: "multiple",
                        },
                    },
                },
            }),
        ];
        return basicColumns.concat(
            data.length > 0
                ? Object.keys(data[0].categories).reduce((yItem: any, ykey: string) => {
                      yItem.push({
                          title: ykey,
                          className: "sub-type-class",
                          children: Object.keys(data[0].categories[ykey].subType).reduce(
                              (zItem: any, zKey: string) => {
                                  zItem.push(
                                      DTColProps.XSmall(
                                          {
                                              title: `${DefaultIfEmpty(SubType, zKey, zKey)}`,
                                              dataIndex: `${ykey}_${zKey}`,
                                              key: `${ykey}_${zKey}`,
                                              render: (text: boolean) =>
                                                  text ? (
                                                      <CheckCircleOutlined className="text-color-success" style={{ fontSize: "1.25rem" }} />
                                                  ) : (
                                                      <CloseCircleOutlined className="text-color-error" style={{ fontSize: "1.25rem" }} />
                                                  ),
                                          },
                                          ["text-center"]
                                      )
                                  );
                                  return zItem;
                              },
                              [
                                  DTColProps.XSmall(
                                      {
                                          title: `${ykey}`,
                                          dataIndex: `${ykey}_ORI`,
                                          key: `${ykey}_ORI`,
                                          render: (text: boolean) =>
                                              text ? (
                                                  <CheckCircleOutlined className="text-color-success" style={{ fontSize: "1.25rem" }} />
                                              ) : (
                                                  <CloseCircleOutlined className="text-color-error" style={{ fontSize: "1.25rem" }} />
                                              ),
                                      },
                                      ["text-center"]
                                  ),
                              ]
                          ),
                      });
                      return yItem;
                  }, [])
                : []
        );
    }, [servers, data]);

    const options: FlexiDataTableOptionsProps = {
        serverFiltering: true,
    };

    const componentCallback: FlexiDataTableCallbackProps = (type, FormData: any) => {
        switch (type) {
            case CALLBACK_KEY.FILTER_FORM_SUBMIT:
                setFilterParams(
                    Object.keys(FormData).reduce((acc: any, key: string) => {
                        if (key === "server") {
                            acc["serverUno"] = FormData[key].join(",");
                        } else if (key === "dateTime") {
                            acc[key] = moment(FormData[key]).format("YYYY-MM-DD HH:mm");
                        } else {
                            acc[key] = FormData[key];
                        }

                        return acc;
                    }, {})
                );
                setRunRefetchDataList(true);
                break;
            default:
                break;
        }
    };

    const getLogs = useCallback(() => {
        setIsLoading(true);
        plainAxiosInstance
            .get(`${APIs.RC_TRANSACTION_ORDER_CHECKER.GET_LOGS}?${new URLSearchParams(filterParams).toString()}`)
            .then((res: any) => {
                setData(res.data && res.data.length > 0 ? res.data : []);
            })
            .finally(() => setIsLoading(false));
    }, [filterParams]);

    const getConfig = () => {
        plainAxiosInstance
            .get(APIs.RC_TRANSACTION_ORDER_CHECKER.GET_SERVERS)
            .then((res: any) => {
                res.data &&
                    res.data.length > 0 &&
                    setServers(
                        SortList(
                            res.data.map((x: any) => ({ text: x.label, value: x.value })),
                            "text"
                        )
                    );
            })
            .finally(() => setIsLoading(false));
    };

    useEffect(() => {
        if (runRefetchDataList) {
            getLogs();
            setRunRefetchDataList(false);
        }
    }, [runRefetchDataList]);

    useEffect(() => {
        getConfig();
    }, []);

    return (
        <div className="transaction-order-checker-container">
            <CardBox title={"Transaction Order Checker"}>
                <FlexiDataTable
                    bordered
                    rowKeyProperty="key"
                    title=""
                    columns={columns}
                    options={options}
                    dataSource={markupData}
                    callback={componentCallback}
                    loading={isLoading}
                    tableProps={{
                        bordered: true,
                    }}
                />
            </CardBox>
        </div>
    );
};

export default TransactionOrderChecker;
